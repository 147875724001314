import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import MyImage from '../components/MyImage/MyImage'
import Bibliography from '../components/bibliography/Bibliography'
import styles from './pages.module.css'

function Home() {
  return (
    <Container className={styles.homeContainer} fluid id="home">
      <Row className={styles.homeRow}>
        <Col md="3">
          <MyImage />
        </Col>
        <Col md="8">
          <Bibliography />
        </Col>
      </Row>
    </Container>
  )
}

export default Home
