import React from 'react'
import { Image, Container, Col, Row } from 'react-bootstrap'
import linkedin from '../assets/linkedin.svg'
import email from '../assets/email.svg'
import styles from './pages.module.css'

function Contact() {
  const emailAddress = 'contact@horia.dev'
  const linkedinName = 'Horia Popa-Stoian'

  return (
    <Container fluid>
      <div className={styles.contactContainer} id="contact">
        <h1 className={styles.contactTitle}>Contact</h1>
        <Row>
          <Col>
            <Image src={linkedin} style={{ height: '30px' }} />
            <div>
              <a
                href="https://www.linkedin.com/in/horia-popa-stoian"
                target="_blank"
                className={styles.linkedinLink}
                rel="noreferrer"
                style={{ padding: 5 }}
              >
                {linkedinName}
              </a>
            </div>
          </Col>
          <Col>
            <Image src={email} style={{ height: '30px' }} />
            <p style={{ padding: 5 }}>{emailAddress}</p>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default Contact
