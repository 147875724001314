import React from "react";
import { Image } from "react-bootstrap";
import myPicture from "../../assets/IMG_2642.jpg";
import styles from "./myImage.module.css";

function MyImage() {
  const companyName = "DocMorris";

  return (
    <div className={styles.pictureContainer}>
      <Image src={myPicture} rounded className={styles.picture} />
      <h2 className={styles.text}>Horia Popa-Stoian</h2>
      <h3 className={styles.text}>Software Engineer</h3>
      <a
        className={styles.companyPage}
        href="https://www.docmorris.de"
        target="_blank"
        rel="noopener noreferrer"
      >
        {companyName}
      </a>
    </div>
  );
}

export default MyImage;
