import React from "react";
import { Chrono } from "react-chrono";

function Timeline() {
  const data = [
    {
      title: "February 2023 - Present",
      cardTitle: "Software Engineer",
      cardSubtitle: "DocMorris",
      cardDetailedText:
        "I embarked on a new adventure, with a new position at DocMorris. I continue my work in the mobile development world, working on the DocMorris and Medpex apps.",
    },
    {
      title: "February 2022 - February 2023",
      cardTitle: "Junior App Developer",
      cardSubtitle: "Prym Consumer Europe",
      cardDetailedText:
        "As a result of my graduation project, Prym offered me the opportunity to complete the development of the prototype into a fully functional application, replacing the legacy system.",
    },
    {
      title: "September 2021 - January 2022",
      cardTitle: "Graduation Project Intern",
      cardSubtitle: "Prym Consumer Europe",
      cardDetailedText:
        "For my graduation project, I conducted research on mobile frameworks to redevelop a B2B application originally built with a deprecated framework. Upon completing the research, I developed a functional prototype of the updated application.",
    },
    {
      title: "February 2020 - July 2020",
      cardTitle: "Frontend Developer Intern",
      cardSubtitle: "Datatrics",
      cardDetailedText:
        "I was responsible for designing and implementing a new UX/UI for a user onboarding dashboard built with Vue.js. This dashboard was created for newly registered users, enhancing their initial experience with a streamlined and intuitive interface.",
    },
  ];
  return (
    <Chrono
      items={data}
      mode="VERTICAL_ALTERNATING"
      useReadMore={false}
      hideControls
      theme={{
        secondary: "transparent",
        primary: "black",
        titleColorActive: "black",
      }}
      disableClickOnCircle
    />
  );
}

export default Timeline;
