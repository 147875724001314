import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./bibliography.module.css";

function Bibliography() {
  return (
    <div>
      <Container>
        <Row>
          <h1 className={styles.bibliographyHeader}>Hi, I'm Horia</h1>
          <p>
            I am a web and app engineer focusing on optimizing existing systems
            to enhance functionality and deliver greater value.
          </p>
          <h2 className={styles.bibliographyHeader2}>Current Job</h2>
          <p>
            Currently working at DocMorris, an online pharmacy, with a
            focus on maintaining and developing new features for both the Medpex
            and DocMorris mobile applications on iOS and Android platforms. One
            of my key contributions has been the integration of health insurance
            NFC card reading into the DocMorris app, enabling users to
            conveniently scan their cards with their smartphones and order
            medication from home.
          </p>
          <h2 className={styles.bibliographyHeader2}>Passions</h2>
          <p>
            Having grown up as an active child, sports have always been a
            significant passion of mine. I have participated in a wide range of
            sports and have competed professionally in Alpine Skiing. In recent
            years, I have developed a strong passion for cycling in various
            forms, often capturing those moments with any device at hand.
            Whether on a road bike or navigating trails on a mountain bike, you
            can most likely find me enjoying the outdoors through cycling.
          </p>
          <Col>
            <h2 className={styles.bibliographyHeader2}>Interests</h2>
            <ul>
              <li>Sports</li>
              <li>Reading</li>
              <li>Photography</li>
            </ul>
          </Col>
          <Col>
            <h2 className={styles.bibliographyHeader2}>Education</h2>
            <ul>
              <li>
              <b>Bachelor of Applied Science</b> - BASc at Saxion University of
                Applied Sciences, 2022
              </li>
              <li><b>PreMaster Computer Science</b> at University Of Twente, 2020</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Bibliography;
