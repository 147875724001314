import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import cv from '../../cv/CV_Horia Alexandru Popa.pdf';

function NavbarComponent() {
  return (
    <Navbar bg="dark" variant="dark" fixed="top" expand="md">
      <Container fluid>
        <Navbar.Brand href="#home">Horia Popa-Stoian</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#experience">Experience</Nav.Link>
            <Nav.Link
              href={cv}
              // download="CV_Horia_Popa_Stoian"
              target="_blank"
              rel="noreferrer"
            >
              Resume
            </Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
