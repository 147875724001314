import React from "react";
import { Container, Row } from "react-bootstrap";
import Timeline from "../components/Timeline/Timeline";
import styles from './pages.module.css'

function Experience() {
  return (
    <Container className={styles.experienceContainer} id="experience" fluid>
      <Row>
        <h2>Experience</h2>
        <Timeline />
      </Row>
    </Container>
  );
}

export default Experience;
