import "./App.css";
import React from "react";
import NavbarComponent from "./components/NavBar/Navbar.js";
import Experience from "./pages/Experience";
import Home from "./pages/Home";
import Contact from "./pages/Contact";

function App() {
  return (
    <div className="App">
      <NavbarComponent />
      <div className="appContainer">
        <Home />
        <Experience />
        <Contact />
      </div>
    </div>
  );
}

export default App;
